declare global {
  interface Window {
    BrazePlugin: BrazeCorePlugin;
  }
}

export interface BrazeCorePlugin {
  initialize(apiKey: string, options: InitializationOptions): boolean;
  changeUser(userId: string): void;
  logCustomEvent(
    eventName: string,
    eventProperties?: undefined | object
  ): boolean;
  getUser(): User | undefined;
  setFirstName(firstName: string | null): boolean;
  setLastName(lastName: string | null): boolean;
  setEmail(email: string | null): boolean;
  setCustomUserAttribute(
    key: string,
    value: CustomUserValues,
    merge?: undefined | false | true
  ): boolean;
}

export interface InitializationOptions {
  enableLogging?: undefined | false | true;
  baseUrl: string;
}
export interface CustomUserValues {
  value: number | boolean | Date | string | string[] | object | object[] | null;
}
export interface User {
  setEmail(email: string | null): boolean;
  setCustomUserAttribute(
    key: string,
    value: CustomUserValues,
    merge?: undefined | false | true
  ): boolean;
  setFirstName(firstName: string | null): boolean;
  setLastName(lastName: string | null): boolean;
}
export interface BrazePlugin {
  init: () => Promise<boolean>;
  changeUserValue(userId: string): void;
  logCustomEventValue(
    eventName: string,
    eventProperties?: undefined | object
  ): void;
  setUserEmail(email: string): void;
  setUserFirstName(firstName: string): void;
  setUserLastName(lastName: string): void;
  setCustomUserAttribute(
    key: string,
    value: CustomUserValues,
    merge?: undefined | false | true
  ): void;
}

export class CordovaBrazePlugin implements BrazePlugin {
  constructor(private readonly brazePlugin: BrazeCorePlugin) {}
  init(): Promise<boolean> {
    return Promise.resolve(true);
  }

  changeUserValue(userId: string): void {
    if (!userId || !this.brazePlugin) {
      return;
    }
    this.brazePlugin.changeUser(userId);
  }

  logCustomEventValue(
    eventName: string,
    eventProperties?: undefined | object
  ): void {
    if (this.brazePlugin) {
      this.brazePlugin.logCustomEvent(eventName, eventProperties);
    }
  }

  setUserEmail(email: string): void {
    if (this.brazePlugin) {
      this.brazePlugin.setEmail(email);
    }
  }

  setUserFirstName(firstName: string): void {
    if (this.brazePlugin) {
      this.brazePlugin.setFirstName(firstName);
    }
  }

  setUserLastName(lastName: string): void {
    if (this.brazePlugin) {
      this.brazePlugin.setLastName(lastName);
    }
  }

  setCustomUserAttribute(
    key: string,
    value: CustomUserValues,
    merge?: undefined | false | true
  ): void {
    if (this.brazePlugin) {
      this.brazePlugin.setCustomUserAttribute(key, value, merge);
    }
  }
}
export class WebBrazeNotificationPlugin implements BrazePlugin {
  constructor(private readonly brazePlugin: BrazeCorePlugin) {}

  init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.brazePlugin) {
        resolve(
          this.brazePlugin.initialize('SOME_API_KEY', {
            baseUrl: 'SOME_URL',
            enableLogging: true
          })
        );
      } else {
        reject(false);
      }
    });
  }

  changeUserValue(userId: string): void {
    if (!userId || !this.brazePlugin) {
      return;
    }
    this.brazePlugin.changeUser(userId);
  }

  logCustomEventValue(
    eventName: string,
    eventProperties?: undefined | object
  ): void {
    if (this.brazePlugin) {
      this.brazePlugin.logCustomEvent(eventName, eventProperties);
    }
  }

  setUserEmail(email: string): void {
    if (this.brazePlugin) {
      const user = this.brazePlugin.getUser();
      if (user) {
        user.setEmail(email);
      }
    }
  }

  setUserFirstName(firstName: string): void {
    if (this.brazePlugin) {
      const user = this.brazePlugin.getUser();
      if (user) {
        user.setFirstName(firstName);
      }
    }
  }

  setUserLastName(lastName: string): void {
    if (this.brazePlugin) {
      const user = this.brazePlugin.getUser();
      if (user) {
        user.setLastName(lastName);
      }
    }
  }

  setCustomUserAttribute(
    key: string,
    value: CustomUserValues,
    merge?: undefined | false | true
  ): void {
    if (this.brazePlugin) {
      const user = this.brazePlugin.getUser();
      if (user) {
        user.setCustomUserAttribute(key, value, merge);
      }
    }
  }
}

export class BrazeNoopPlugin implements BrazePlugin {
  init(): Promise<boolean> {
    return Promise.reject(false);
  }
  changeUserValue(): void {
    return;
  }
  logCustomEventValue(): void {
    return;
  }
  setUserEmail(): void {
    return;
  }
  setUserFirstName(): void {
    return;
  }
  setUserLastName(): void {
    return;
  }
  setCustomUserAttribute(): void {
    return;
  }
}
